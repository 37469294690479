// Copyright © 2019 3D Robotics. All rights reserved.

import { map } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CloudApi from '../../CloudApi';
import styles from './DataProcessLog.module.scss';

export default class DataProcessLog extends Component {
  /*
  just a wrapper that manages `startFromHead`, and delegates everything else to DataProcessLogInner.
  this design is mainly to ensure a fresh state when toggling `startFromHead`.
  */

  static propTypes = {
    dataProcessId: PropTypes.string.isRequired,
  };

  state = {
    startFromHead: false,
    complete: false,
  };

  setStartFromHead(newValue) {
    this.setState({
      startFromHead: newValue,
    });
  }

  handleComplete() {
    this.setState({
      complete: true,
    });
  }

  render() {
    const setFromHead =
      this.state.complete || this.state.startFromHead
        ? null
        : () => this.setStartFromHead(true);
    const setFromTail =
      this.state.complete || !this.state.startFromHead
        ? null
        : () => this.setStartFromHead(false);
    return (
      <div style={{ padding: '2em' }}>
        <DataProcessLogInner
          key={this.state.startFromHead}
          dataProcessId={this.props.dataProcessId}
          startFromHead={this.state.startFromHead}
          onComplete={() => this.handleComplete()}
          onSetFromHead={setFromHead}
          onSetFromTail={setFromTail}
        />
      </div>
    );
  }
}

class DataProcessLogInner extends Component {
  static propTypes = {
    dataProcessId: PropTypes.string.isRequired,
    startFromHead: PropTypes.bool.isRequired,
    onComplete: PropTypes.func.isRequired,
    onSetFromHead: PropTypes.func,
    onSetFromTail: PropTypes.func,
  };

  state = {
    status: 'loading',
    logs: [],
    forwardToken: null,
    backwardToken: null,
    selectionTime: null,
  };

  constructor(props) {
    super(props);
    this.selectionChange = this.selectionChange.bind(this);
  }

  componentDidMount() {
    this.reload();
    document.addEventListener('selectionchange', this.selectionChange);
  }

  componentWillUnmount() {
    this.request.abort();
    document.removeEventListener('selectionchange', this.selectionChange);
  }

  appendNewLogs(isForward, newLogs) {
    this.setState((oldState, props) => {
      const logs = isForward
        ? oldState.logs.concat(newLogs)
        : newLogs.concat(oldState.logs);
      return {
        logs,
      };
    });
  }

  onClickMoreButon(isForward, stateTokenKey) {
    this.request = CloudApi.post(
      `dataprocess/${this.props.dataProcessId}/logs`,
      this.state[stateTokenKey],
    );
    this.setState(this.state[stateTokenKey]);

    const newState = {
      status: 'loading',
    };
    newState[stateTokenKey] = null;
    this.setState(newState);

    this.request.then((response) => {
      this.handleLogsResponse(isForward, response);
    });
  }

  moreButton(isForward) {
    const stateTokenKey = isForward ? 'forwardToken' : 'backwardToken';
    return (
      <button
        enabled={(this.state.status !== 'loading').toString()}
        onClick={() => this.onClickMoreButon(isForward, stateTokenKey)}
      >
        Load more
      </button>
    );
  }

  handleLogsResponse(isForward, response) {
    this.appendNewLogs(isForward, response.body.items);
    this.setState((state) => {
      if (isForward) {
        Object.assign(state, { forwardToken: response.body.next });
      } else {
        Object.assign(state, { backwardToken: response.body.back });
      }
      Object.assign(state, { status: 'loaded' });

      if (!state.forwardToken && !state.backwardToken) {
        this.props.onComplete();
      }

      return state;
    });
  }

  reload() {
    this.setState({
      status: 'loading',
      forwardToken: null,
      backwardToken: null,
    });
    this.request = CloudApi.get(
      `dataprocess/${this.props.dataProcessId}/logs?startFromHead=${this.props.startFromHead}`,
    );

    this.request.then(
      (response) => {
        this.setState({ logs: [] });
        this.setState({
          forwardToken: response.body.next,
        });
        this.handleLogsResponse(this.props.startFromHead, response);
      },
      (error) => {
        this.setState({
          status: error,
        });
      },
    );
  }

  render() {
    const loadingStatus =
      this.state.status === 'loading' ? <div>Loading...</div> : null;

    return (
      <div style={{ flex: 1, overflow: 'auto' }}>
        <div>
          {this.props.onSetFromHead ? (
            <button onClick={this.props.onSetFromHead}>Jump to start</button>
          ) : null}
          {this.state.backwardToken ? this.moreButton(false) : null}
          {loadingStatus}
        </div>

        {this.state.selectionTime && (
          <div className={styles.selectionTime}>
            Selected: {formatDuration(this.state.selectionTime)}
          </div>
        )}

        <div className={styles.logOutput}>
          {map(this.state.logs, (log, i) => {
            const key = this.props.startFromHead
              ? i
              : this.state.logs.length - i;

            return (
              <div key={key} data-log-time={log.timestamp}>
                <span className={styles.logOutputItemTimestamp}>
                  {
                    new Date(
                      log.timestamp,
                    ).toISOString() /* for spacing when copying as plain text: */
                  }
                  &nbsp;
                </span>
                {log.msg}
              </div>
            );
          })}
        </div>

        {this.state.status instanceof Error && (
          <div>
            There was an error retrieving logs. {this.state.status.toString()}
          </div>
        )}

        <span>
          {this.state.logs.length > 10 ? loadingStatus : null}
          {this.state.forwardToken ? this.moreButton(true) : null}
          {this.props.onSetFromTail ? (
            <button onClick={this.props.onSetFromTail}>Jump to end</button>
          ) : null}
        </span>
      </div>
    );
  }

  selectionChange() {
    const selection = document.getSelection();

    function timeOf(node) {
      const msg = node.parentElement.closest('[data-log-time]');
      if (msg) {
        return new Date(parseInt(msg.getAttribute('data-log-time'), 10));
      } else {
        return null;
      }
    }

    const t1 = timeOf(selection.anchorNode);
    const t2 = timeOf(selection.focusNode);

    const selectionTime = t1 && t2 && t1 - t2 !== 0 ? Math.abs(t1 - t2) : null;
    this.setState({ selectionTime });
  }
}

function formatDuration(dur) {
  const hours = Math.floor(dur / 1000 / 60 / 60);
  const minutes = Math.floor((dur % (1000 * 60 * 60)) / 1000 / 60);
  const seconds = (dur % (1000 * 60)) / 1000;

  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds.toFixed(3)}s`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds.toFixed(3)}s`;
  } else {
    return `${seconds.toFixed(3)}s`;
  }
}
